<template>
  <div class="container">
    <h1>Feriados</h1>

    <v-btn
      class="ma-2"
      color="primary"
      @click="$router.push({ name: 'holidays.create' })"
    >
      Novo
    </v-btn>

    <v-btn
      class="ma-2"
      color="error"
      @click="$router.push({ name: 'Cities.index' })"
    >
      Voltar
    </v-btn>

    <v-data-table
      disable-pagination
      hide-default-footer
      :items="holidays"
      :headers="headers"
      :loading="loading"
    >
      <template v-slot:[`item.city_id`]="{ item }">
        {{ item.city.name }}
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{
          item.date ? `${item.date.slice(8, 10)}/${item.date.slice(5, 7)}` : ""
        }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          :disabled="loading"
          color="error"
          @click="(event) => deleteHoliday(event, item.id)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  mounted() {
    this.getHolidays();
  },

  data() {
    return {
      loading: false,
      headers: [
        { text: "Cidade", value: "city_id" },
        { text: "Dia", value: "date" },
        { text: "Ações", value: "actions" },
      ],
      holidays: [],
    };
  },

  methods: {
    async getHolidays() {
      this.loading = true;
      try {
        const { data } = await this.$http.get(`api/holidays`);
        this.holidays = data;
        console.log("holidays", data);
      } catch (error) {
        this.$toast.error("Erro ao carregar feriados");
      }
      this.loading = false;
    },

    async deleteHoliday(holidayId) {
      if (confirm("Tem certeza que deseja excluir este feriado?")) {
        this.loading = true;
        try {
          await this.$http.delete(`api/holidays/${holidayId}`);
          this.$toast.success("Removido");
          this.holidays = this.holidays.filter(
            (holiday) => holiday.id !== holidayId
          );
        } catch (error) {
          this.$toast.error("Erro ao remover");
        }
        this.loading = false;
      }
    },
  },
};
</script>
